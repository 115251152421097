div.joinedButton {
    display: flex !important;
    margin: 0 10px !important;
    & > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    button {
        margin: 0 !important;
        padding: 0 !important;
        width: 40px;
        height: 40px;
        i {
            font-size: 24px;
        }
    }
    span {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 6px;
        font-size: 14px;
    }
}

div.joinedButton button:nth-of-type(1) {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right-width: 1px;
    border-right: 1px solid black !important;
}

div.joinedButton button:nth-of-type(2) {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left-width: 0px !important;
}
