//
//div.popup-container {
//  position: fixed;
//  background: #333;
//  color:white;
//  border: 1px solid black;
//  border-radius: .125rem;
//  padding: .25rem;
//  width: fit-content;
//  div.popup-bubble-anchor {
//    div.popup-bubble {
//      font-family: monospace;
//    }
//  }
//}
/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;

    /* The parent of the bubble. A zero-height div at the top of the tip. */
    .popup-bubble-anchor {
        /* Position the div a fixed distance above the tip. */
        position: absolute;
        width: 100%;
        bottom: /* TIP_HEIGHT= */ 8px;
        left: 0;

        /* This element draws the tip. */
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            /* Center the tip horizontally. */
            transform: translate(-50%, 0);
            /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
            width: 0;
            height: 0;
            /* The tip is 8px high, and 12px wide. */
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: /* TIP_HEIGHT= */ 8px solid white;
            opacity: 0;
        }
        /* The popup bubble styling. */
        .popup-bubble {
            /* Position the bubble centred-above its parent. */
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -100%);
            /* Style the bubble. */
            background-color: white;
            padding: 5px;
            border-radius: 5px;
            font-family: sans-serif;
            overflow-y: auto;
            max-height: 60px;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: all ease 0.5s;
            &:hover {
                opacity: 1;
            }
            i {
                display: block;
                text-align: right;
                pointer-events: none;
            }
        }
    }
}
