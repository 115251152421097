@import url("https://fonts.googleapis.com/css?family=Cairo");
// default colors
$dark-gray: #363636;
$light-gray: #f2f4f5;
// accent colors
$blue: #23408f;
$orange: #f58220;
$white: #fff;
// ...
// ...
$defaultButtonWhite: #fefefe;
$defaultButtonBlack: #000;
$accentColor: $orange;
// <FooterIntercom>
$footer-header-color: $white;
$footer-header-background-color: $blue;
// <hr>
$footer-hr-color: $blue;
//...
$min-button-size: 48px;
// ...
:export {
    footerHeaderColor: $footer-header-color;
    footerHeaderBackgroundColor: $footer-header-background-color;
}

.flex-center {
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.text-center {
    text-align: center;
}
