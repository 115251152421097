@import "src/styles/defaults";
.i-button {
    background-color: #2e55a0;
    color: white;
    border: none;
    position: relative;
    height: 48px;
    padding: 0px;
    font-size: 18px;
    cursor: pointer;
    &.orange {
        background-color: $orange;
    }
    &.blue {
        background-color: $blue;
    }
    .i-button-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;
        padding: 0 24px;
        &::before {
            content: "";
            position: absolute;
            width: 8.5px;
            height: 100%;
            left: -8px;
            clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%);
            background-color: $orange;
        }
        &::after {
            content: "";
            position: absolute;
            width: 8.5px;
            height: 100%;
            right: -8px;
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 110%);
            background-color: $orange;
        }
        & > * {
            margin: 0px 6px;
            color: inherit;
            text-decoration: none;
        }
        & > a {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
