.toggleButton {
    top: 6px;
    left: 6px;
    height: 20px;
    width: 20px;
    position: absolute;
    border: 1px solid black;
    background-color: #ffffff;
    color: black;
}

.bottomFix {
    padding: 0 8px 2rem 8px;
}

.rightFix {
    padding: 0px 0px 16px 16px;
}

.leftFix {
    padding: 0px 0px 16px 16px;
    width: 2rem;
    height: 2rem;
    background-color: #113322dd;
}
