div.selectionDetails {
  // A border to visualize that all values
  // in this box belong together vs. e.g. total area
  border: 1px solid #333;
  padding: 8px;

  // Fixed min-sizes so we get less re-flow
  min-height: 116px;
  min-width: 185px;
  width: max-content;

  // Bump font-size to be more readable
  font-size: 14px;

  // Add row lines to make data easier to read
  td,th {
    border-bottom: 1px solid #BBB;
  }

  // Align the titles (outer, inner, area) on the left side of the
  // table to avoid position differences between different elements
  th {
    text-align: start;
    padding-right: 8px;
  }
  // Align the data on the right side of the
  // table to avoid position differences between different elements
  td {
    padding-left: 8px;
    text-align: end;
    // Fill all space remaining after th
    width: 100%;
  }
}
